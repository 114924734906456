.searchBar {
    float: left;
    padding: 0 0 20px 0;
}

.inlineLabel {
    padding: 6px;
}

.searchInput {
    margin-right: 50px;
}

.r20 {
    margin-right: 20px;
  }
