#sidebar {
    width: 160px;
    height: 100%;
    position: fixed;
    top: 65px;
    background-color: #f7f7f7;
    box-shadow: 2px 0 2px #c2c2c2;
    z-index: 1;
}

#sidebar div {
    font-size: 12px;
    padding: 10px 0 10px 10px;
    border-bottom: dashed 1px #636363;
    text-transform: uppercase;
}

#sidebar .submenu {
    font-size: 10px;
    padding: 10px 0 0 25px;
    display: block;
}

#sidebar a, #sidebar span{
    color: #7e7e7e;
    text-decoration: none;
}

#sidebar i {
    font-size: 20px;
    width: 20px;
    margin-right: 10px;
}
