.userProfile {
    text-align: left;
}

.userProfile :global(.form-label) {
    font-size: 14px;
    padding-top: 10px;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}
