body {
  font-family: 'Roboto', sans-serif;
}

#main {
  width: 100%;
}

#content {
  margin: 85px 20px 20px 180px;
}

.pn-green, a {
  color: #8FC248;
}

a:hover  {
  color: #628630;
}

.pn-green-bg, .btn-primary  {
  background-color: #8FC248;
  border-color: #8FC248;
}

.btn-outline-primary {
  color: #ffffff;
  background-color: #8FC248;
  border-color: #8FC248;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #628630;
  border-color: #628630;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active {
  color: #ffffff;
  background-color: #628630;
  border-color: #bcbeb8;
}

.btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem #8FC248;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #64912591;
  border-color: #62863091;
}

.form-control:focus {
  border-color: #8FC248;
  box-shadow: 0 0 0 0.1rem #62863057;
}

.pn-red {
  color: #a50d31;
}

.pn-yellow {
  color: #dbab0c;
}

.pn-red-bg {
  background-color: #a50d31;
}

.pn-grey {
  color: #7e7e7e;
}

.white {
  color: #ffffff;
}

.white-bg {
  background-color: #ffffff;
}

.clearfix {
  clear: both;
}

.pn-section-header {
  text-transform: uppercase;
  font-size: 30px;
  margin: 20px 0px 10px;
}

.sm-container {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin: 100px auto;
  padding: 30px;
  background: #fff;
  width: 500px;
}

.title {
  font-size: 24px;
}

.centered {
  text-align: center;
}

.txt-right{
  text-align: right;
}

.font-sm {
  font-size: 12px;
}

i.fa-download {
  margin-right: 5px;
}
