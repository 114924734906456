.pnBrand {
    margin-left: 40px;
    line-height: 40px;
}

.pnNavbar {
    box-shadow: 0 0 0 0.1rem #628630f0;
}

@media screen and (max-width: 600px) {
    .pnBrand {
        font-size: 20px;
        margin-left: 40px;
    }
}
