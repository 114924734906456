.orContainer {
    text-align: center;
    margin-top: 20px;
    color: #808080;
    font-variant: small-caps;
}

#or {
    display: inline-block;
    position: relative;
    padding: 10px;
    background-color: #fff;
}

.orHr {
    margin-bottom: -20px;
    height: 0;
    border: 0;
    border-top: 1px solid #e6e6e6;
}

#resetPassword {
    display: inline;
}

#newAccount {
    margin-top: 20px;
}
