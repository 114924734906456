.listButton {
    padding: 2px;
    margin-left:10px;
}

.checksumField {
    font-size: 11px;
    word-break: break-all;
    font-family: "Bitstream Vera Sans Mono", Monaco, "Courier New", Courier, monospace;
}

.checksumField .md5 {
    display: inline-block;
    width: calc(100% - 40px);
}

.checksumField .md5copy {
    display: inline;
}

.checksumField button {
    float: right;
}
