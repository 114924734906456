.blockLabel {
    display: block;
    text-align: left;
    font-weight: bold;
}

.buttonsContainer {
    float: right;
}

.buttonsContainer button {
    margin-left: 10px;
}
