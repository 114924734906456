.pnActiveLicense {
    border-left:solid 8px #8FC248;
    background-color: #F9F9F9;
    padding:7px;
}

.pnLicense {
    border-left: solid 1px #979797;
    background-color: #F9F9F9;
    padding:7px;
    color: #7e7e7e;
}

#activate {
    margin-right: 10px;
}
