@media screen and (max-width: 600px) {
    #userMenu {
        font-size: 12px;
    }
}

.rightMenu {
    left: inherit;
    right: 0;
    text-align: right;
}
