.navbarDesc {
    font-size:10px;
    margin-top: -5px;
    margin-left: 17px;
    text-align: left;
}

.resultsCount {
    text-align: left;
    padding: 0 0 20px 20px;
}

.linkInactive {
    pointer-events: none;
    color: #888888;
}
