.listItem {
    display: block;
    padding: .75rem 1.25rem;
    border-bottom: 1px solid #eee;
}

#switchImage {
    max-width: 100%;
    padding: 10px;
}

#buttons {
    margin-top:10px;
}

#buttons button{
    margin-right: 20px;
}
